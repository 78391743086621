import { ChangeEvent } from 'react';
import { BtnPrimary, FormSection, Section, SectionContent } from '../common/ui-component';
import { Divider } from '@mui/material';

type Props = {
  onUploadCSV: (file: File) => void;
};

const TrackingNumberCsvUploadSection = ({ onUploadCSV }: Props) => {
  const handleUploadCSV = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      onUploadCSV(event.target.files[0]);
    }
  };
  return (
    <Section title="Upload LifeFile Tracking Numbers">
      <SectionContent>
        <p>Upload a CSV list of tracking numbers, which should be provided daily by ChemRX</p>
      </SectionContent>
      <FormSection title={''}>
        <Divider>
          <BtnPrimary component="label">
            Upload CSV
            <input
              style={{ display: 'none' }}
              type="file"
              accept=".csv"
              onChange={(event) => handleUploadCSV(event)}
              multiple={false}
            ></input>
          </BtnPrimary>
        </Divider>
      </FormSection>
    </Section>
  );
};

export default TrackingNumberCsvUploadSection;
