import axios from 'axios';
import useService from '../../common/useService';
import { ChemistryrxOrder } from './chemistryrx-order.model';
import { CommentDto } from './comment.dto';

const useGetOrder = () =>
  useService(({ orderId, version }: { orderId: number; version: number }) =>
    axios.get<ChemistryrxOrder>(`/chemistryrx/${orderId}/${version}`),
  );
const useComment = () =>
  useService((commentDto: CommentDto) =>
    axios.post<ChemistryrxOrder>(`/chemistryrx/comment/${commentDto.orderId}/${commentDto.version}`, commentDto),
  );

const useDownloadChemRxPdf = () =>
  useService(({ orderId, version }: { orderId: number; version: number }) =>
    axios.get<Blob>(`/chemistryrx/${orderId}/${version}/pdf`, { responseType: 'blob' }),
  );

const useUploadTrackingNumberCSV = () =>
  useService((file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    return axios.post(`/chemistryrx/lifefile/upload-tracking-numbers`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  });
const ChemistryrxService = {
  useGetOrder,
  useComment,
  useDownloadChemRxPdf,
  useUploadTrackingNumberCSV,
};

export default ChemistryrxService;
